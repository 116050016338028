import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./AuthProvider";
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  progressLoader: {
    margin: theme.spacing(10),
  }
}));



const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const {currentUser, appLoaded} = useContext(AuthContext);

  const classes = useStyles();
    const renderLoader = () => {

      // const { currentUser } = useContext(AuthContext);

      return ( <Grid
                  spacing={15}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center">
                  <CircularProgress  className={classes.progressLoader} />
              </Grid>)
  }
  return (
    <Route
      {...rest}
      render={(routeProps) => {

        if (!appLoaded) {
          return ( renderLoader() )
        } else 
        if (!!currentUser ) {
          return <RouteComponent {...routeProps} />
        } else {
          return <Redirect to={"/login"} />
        }
      }
      }
    />
  );
};


export default PrivateRoute