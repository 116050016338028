import firebase from "firebase";


const config = {
    apiKey: "AIzaSyAE7UYxjLuRZmNZDaLFHtOSHbdIrsZHh3Q",
    authDomain: "rbfraudawareness.firebaseapp.com",
    databaseURL: "https://rbfraudawareness.firebaseio.com",
    projectId: "rbfraudawareness",
    storageBucket: "rbfraudawareness.appspot.com",
    messagingSenderId: "1031968466724",
    appId: "1:1031968466724:web:2914f2180ab5a7af2ef5b1"
  };

let firebaseObj

export default !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();