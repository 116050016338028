import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function FormDialog(props) {
    const [open, setOpen] = React.useState(false);

    const [isFraud, setIsFraud] = React.useState(false);
    const [note, setNote] = React.useState('');
    
    useEffect(() => {

        if (!!props.show) {
          setOpen(true)
        } 
  
     }, [props.show]);

     const handleClose = () => {
        setOpen(false);
    
    
         props.handleClose()
      };

     let fraudLabel =  !isFraud ?  <p style={{
        background: "#009688",
        color: "#fff",
        padding: '8px', 
        borderRadius: '3px',
        textAlign: 'center',
        fontSize: '16px',
        textTransform: 'uppercase',
        marginTop: '0',
    }}>This is not a fraud</p>:<p style={{
      background: "#f44336",
      color: "#fff",
      padding: '8px', 
      borderRadius: '3px',
      textAlign: 'center',
      fontSize: '16px',
      textTransform: 'uppercase',
      marginTop: '0',
  }}>It is a fraud</p>

  return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Resolve</DialogTitle>
        <DialogContent>

          {fraudLabel}
      
          <FormControlLabel
              control={<Switch checked={isFraud} onChange={(event) => {
                setIsFraud(event.target.checked)
              }} />}
              label="Is this Fraud"
            />

          <TextField
            checked={note} 
            autoFocus
            margin="dense"
            id="name"
            label="Note"
            type="text"
            rows={4}
            fullWidth
            multiline
            onChange={(event) => {
                setNote(event.target.value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => {
              props.handleAction({
                  note: note,
                  is_fraud: isFraud,
                  is_resolved: true,
              }) 
              handleClose();
          }} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
  );
}