// react related 
import React, {useContext} from 'react';
import clsx from 'clsx';



// material ui
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';

import { ThemeProvider } from '@material-ui/styles';

import { createMuiTheme } from '@material-ui/core/styles';
import deepPurple from '@material-ui/core/colors/deepPurple';
import blue from '@material-ui/core/colors/blue';


// dependecies
import {withRouter} from 'react-router';
import { BrowserRouter as Router ,Route } from "react-router-dom";


// providers
import { AuthProvider } from "./common/AuthProvider";
import { MainProvider } from "./common/MainProvider";
import PrivateRoute from "./common/PrivateRoute";
import { AuthContext } from './common/AuthProvider';



// pages
import EmployeeSubmissions from './page/employeesubmissions';
import CompanySubmissions from './page/companysubmissions';
import Notification from './page/notification';
import Report from './page/report';
import Login from './page/login';


//items 
import Topbar from './layout/topbar';
import Sidebar from './layout/sidebar';
import Message from './layout/message';


// theming 
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#009688",
    },
    secondary: {
      main: "#37433E"
    },
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
}));




function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
      Khalaha Lk
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


export function Layout() {

  const context = useContext(AuthContext);


  const getLayout = () => {

      if (context.currentUser) {
        return <React.Fragment>
               <Topbar /> 
                <Sidebar />
          </React.Fragment>
      } else {
        return <React.Fragment></React.Fragment>
      }
  }
  return (  getLayout() )
             
}



function App() {

  const classes = useStyles();


  return (
  	<ThemeProvider theme={theme}>
	  	<AuthProvider>
	  		<MainProvider>
			    <div className="App">
			  		 <Router>
			              <div className={classes.root}>
			                <CssBaseline />
			                <Layout />
			                <main className={classes.content}>
			                  <div className={classes.appBarSpacer} />
			                     <PrivateRoute exact 
                            path="/submissions" 
                            classes={classes} 
                            component={EmployeeSubmissions} 
                          />
                 

                          <PrivateRoute exact 
                            path="/" 
                            classes={classes} 
                            component={Report} 
                          />


			                    <Route exact 
			                      path="/login" 
			                      component={Login} 
			                    />
                           <Message />
			                     <Box mt={8}>
							        <Copyright />
							      </Box>
			                </main>
			              
			              </div>
			            </Router>
			    </div>
			</MainProvider>
	    </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
