import React, {useEffect, useContext} from 'react';
import { withRouter, Redirect } from "react-router";
import moment from "moment";
import uuid from 'uuid';


// Material UI
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';


// providers
import  MainProvider  from "../common/MainProvider";
import AuthContext from '../common/AuthProvider';
import MainContext from '../common/MainProvider';
import NotificationPreview from '../components/notificationpreview';
import NotificationList from '../components/notificationlist';



//firebase connection 
import firebaseConfig from '../config/firebaseConfig';


const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  title: {
    flexGrow: 1,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
  	marginTop: 20,
  	backgroundColor: theme.palette.secondary
  }
}));



export default  function Notification() {

  const context  = useContext(MainContext);
  
   const [values, setValues] = React.useState({
      titleError: false,
      messageError: false,
    });



    const [sentNotifications, setSentNotifications] = React.useState([]);
    const [title, setTitle] = React.useState("");
    const [message, setMessage] = React.useState("");


   useEffect(() => {

   	   if (context.actions) {
          context.actions.setPageTopConent(setHeader())
       }


       fetchSentNotifications()
   }, []);



  const classes = useStyles();


  const setHeader = () => {
  	return <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                  Send Notifcation
           </Typography>
  }



  const fetchSentNotifications = () => {
      var db = firebaseConfig.firestore();

        db.collection('notification_sent')
        .orderBy('created_at', 'desc')
        .get()
        .then((notifications) => {

            let notificationArray  = []
            notifications.forEach((item) => {
              let dataJson = item.data();
              notificationArray.push({
                title: dataJson.title,
                message: dataJson.message,
                created_at: moment(dataJson.created_at * 1000).format('YY/MM/DD'),
              })

            })
            setSentNotifications(notificationArray)

        })






  }


  const handleFormSubmission = async (event) =>  {
  	event.preventDefault();
  	const { title, message } = event.target.elements;

  	let newState = {}

    let hasError = false

    if (title.value == "") {
        newState.titleError = true
        hasError = true
    }

    if (message.value == "") {
        newState.messageError = true
        hasError = true
    }

    setValues( newState )

    if (!hasError) {

    	context.actions.showProgress(true)
    	//   var request = await fetch('https://us-central1-coin-collector-rb.cloudfunctions.net/sendMessage',{
		   //    headers: {
			  //   'Accept': 'application/json',
			  //   'Content-Type': 'application/json'
			  // },
		   //    method: "post",
	    //       body: JSON.stringify({
	    //         title: title.value,
	    //         message: message.value,
	    //       })
		   //  });

    	 // if (request.json().sent) {
    	 	
        var db = firebaseConfig.firestore();

        db.collection('notification_sent')
        .doc(uuid())
        .set({
          title: title.value,
          message: message.value,
          created_at: moment().unix()
        })


        fetchSentNotifications()

        context.actions.showMessage({
    	 		message: "Notification is scheduled to send",
    	 		type: "success",
    	 	}) 

    	 	context.actions.showProgress(false)
        setTitle("")
        setMessage("")
    	 	document.getElementById("notifcation-form").reset();

    	 // }

    }

  }

  const generateForm = () => {
    return ( <form id="notifcation-form" noValidate autoComplete="off" onSubmit={handleFormSubmission}>

              <Grid container spacing={1}>

                  <Grid item xs={12} md={12}>
                  <Typography variant="h6" gutterBottom>
                 Compose Notification
                </Typography>
                </Grid>
                  <Grid item xs={12} md={12}>

                    <TextField 
                      error={values.titleError}
                      variant="outlined"
                      inputProps={{ maxLength: 40 }}
                      onChange={value => setTitle(value.target.value)}
                      required id="title" label="Notifcation Title" fullWidth />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField error={values.messageError} required id="message" label="Notification Message" 
                    variant="outlined"
                     onChange={ value =>  setMessage(value.target.value) } 
                     inputProps={{ maxLength: 100 }}
                      rowsMax="4"
                     fullWidth />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                   <Button
                    type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.button}
                        >Send Notification</Button>
                </Grid>
        </form>)
  }

  const notificsationRender = () => {

    if (title != "" || message != "") {
          return (  <React.Fragment><Divider/><NotificationPreview
                  type="success" 
                  message={message} 
                  title={title} /></React.Fragment> )
    }

  }


  const renderSentNotification = () => {


    if (sentNotifications.length >0) {

       return( <Grid item xs={12} md={12} lg={12} style={{ marginTop: "20px"}}>
              <Divider />
              <Typography variant="h6" gutterBottom style={{ marginTop: "20px"}}>
                 Notifications Sent
              </Typography>
              <NotificationList sentNotifications={sentNotifications} />
            </Grid>)
    } else {
      return ""
    }
   
  }
  return (
    <Container maxWidth="lg" className={classes.container}>
	  <Grid container spacing={3}>
	    <Grid item xs={12} md={12} lg={12}>
	      <Paper>
          <Container className={classes.container}>
	      	<Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <div>{generateForm()}</div>
            </Grid>
            <Grid item xs={12} md={6} lg={6} >

                <Typography variant="h6" gutterBottom>
                 Device Preview
                </Typography>

              <Typography gutterBottom variant="body1" gutterBottom>
                This preview provides a general idea of how your message will appear on a mobile device. 
              </Typography>
              
              {
                notificsationRender()

              }

              {
                renderSentNotification()
              }

              
            </Grid>

            </Grid>
			     </Container>

	      </Paper>
	    </Grid>
	  </Grid>

	</Container>
  );
}
