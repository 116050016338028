import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import MUIDataTable from 'mui-datatables';
import CustomSearchRender from './customsearch.jsx';

export default class Datatable extends React.Component {
  render() {


      
    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'stacked',
      page: 0,
      print: false,
      searchText: this.props.searchText,
      searchPlaceholder: 'Search user by name',
      selectableRows: 'none',
      count: this.props.totalCount,
      serverSide: true,
      onTableChange: this.props.handleTableChange,
      onFilterChange: this.props.handleFilterChange,
      onSearchChange: this.props.handleSearchChange,
      onColumnSortChange: this.props.handleSortChange,
      onChangePage: this.props.handleChangePage,
      onChangeRowsPerPage: this.props.handleRowChange,
     
      // customSearch: (searchQuery, currentRow, columns) => {
      //   let isFound = false;
      //   currentRow.forEach(col => {
      //     if (col.toString().indexOf(searchQuery) >= 0) {
      //       isFound = true;
      //     }
      //   });
      //   return isFound;
      // },
     customSearchRender: (searchText, handleSearch, hideSearch, options) => {

        return (
          <CustomSearchRender
            searchText={searchText}
            onSearch={handleSearch}
            onHide={hideSearch}
            options={options}
          />
        );
      }
    };
    return (
      <Fragment>
        {/*<button onClick={() => this.setState({ searchText: '' })}>Reset Search</button>*/}
        <MUIDataTable 

          title={this.props.tableTitle} 
          data={this.props.data} 
          columns={this.props.tableColomns} 
          options={options} 
          />
      </Fragment>
    );
  }
}

