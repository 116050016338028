import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import NotificatoinIcon from '@material-ui/icons/NotificationImportant';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

export default function NotificationList(props) {
  const classes = useStyles();

  return (
    <List className={classes.root}>
     
      { props.sentNotifications.map((notification) => {
          return(<ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar className={classes.avatar}>
                             <NotificatoinIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={notification.title}
                          secondary={
                            <React.Fragment>
                             <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                              >
                                {notification.created_at}
                              </Typography>
                               { " - " + notification.message}
                            </React.Fragment>
                          }
                        />
                      </ListItem>)
      })}
      
    </List>
  );
}