import React, { useEffect, useState } from "react";

import firebaseConfig from '../config/firebaseConfig';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [appLoaded, setAppLoaded] = useState(false);

  useEffect(() => {
    firebaseConfig.auth().onAuthStateChanged((user) => {
        setCurrentUser(user) 

        setAppLoaded(true)

    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        appLoaded
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};