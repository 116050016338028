import React, {useEffect, useContext} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SubmissionsChart from '../components/SubmissionsChart';
import TotalSubmissions from '../components/TotalSubmissions';

import CircularProgress from '@material-ui/core/CircularProgress';
import RefreshIcon from '@material-ui/icons/Refresh'

import ZoneData from '../components/ZoneData.jsx';
import LevelData from '../components/levelData.jsx';
//firebase connection 
import firebaseConfig from '../config/firebaseConfig';

import AuthContext from '../common/AuthProvider';
import MainContext from '../common/MainProvider';
import uuid from 'uuid';
import moment from 'moment';
 var db = firebaseConfig.firestore();

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },

  progressLoader: {
    margin: theme.spacing(2),
    alignSelf: 'center',
    justifyContent: "center",
  },

  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

var zoneCoinCount = {},
 zoneSafeCount = {},
 levelPointCounts = {},
 levelSafeCounts = {};
export default function Reports() {
  const classes = useStyles();


  const [totalSubmissions, setTotalSubmissions] = React.useState(0);
  const [chartData, setChartData] = React.useState([]);

  const [zoneTableCol, setZoneTableCol] =  React.useState([]);
  const [zoneData, setZoneData] =  React.useState([]);
  const [levelData, setLevelData] =  React.useState([]);
  const [chartLoaded, setChartLoaded] =  React.useState(false);
  const [tableLoaded, setTableLoaded] =  React.useState(false);
  const [levelTableLoaded, setLevelTableLoaded] =  React.useState(false);
  const [countLoaded, setCountLoaded] =  React.useState(false);
  

  const context  = useContext(MainContext);
  
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);


   useEffect(() => {

      // firebaseConfig.firestore().collection('locations').doc("TZgLEGSezEAWzD6jtgGe").set({
      //     locations: 
      // })

       zoneCoinCount = {};
       zoneSafeCount = {};
       levelPointCounts = {};
       levelSafeCounts = {};


       if (context.actions) {
          context.actions.setPageTopConent(setHeader())
       }


       getTotalSubmissions()
       fetchChartData()
       getLatestEmployeeData()
       getLatestCompanyArray()
  
       // var db = firebaseConfig.firestore();
       // const batch = db.batch()
       // for (var i = 0; i < 20; i++) {
       //    let pointRef = db
       //        .collection("scanned_coins")
       //        .doc(uuid())

       //      batch.set( pointRef, {
       //          level: "l1",
       //          points: i + 1,
       //          safe: false ,
       //          scannedAt: false ,
       //          uid: "weqwewe" ,
       //          zone: "2IRGapSXPRF3k8nQOyC5" ,
       //          scannedAt: moment().add(i + 5, 'days').unix() ,
       //      })
       // }


       //  batch.commit();

       // var db = firebaseConfig.firestore();

       //  db.collection('scanned_coins')
       //  .doc(uuid())
       //  .set({
       //    level: "l1",
       //    points: 1,
       //    safe: false,
       //    uid: "weqwewe",
       //    zone: "2IRGapSXPRF3k8nQOyC5",
       //    scannedAt: moment().add(2, 'days').unix(),
       //  })


       // setZoneData([
       //    {id: 1,  zone:"Zone 1", zonePoints: 2000, scannedPoints: 100, zoneSafes: 100, scannedSafes: 100 },
       //    {id: 2,   zone:"Zone 2", zonePoints: 2000, scannedPoints: 100, zoneSafes: 100, scannedSafes: 100 },
       //    {id: 3,   zone:"Zone 3", zonePoints: 2000, scannedPoints: 100, zoneSafes: 100, scannedSafes: 100 },
       //    {id: 4,   zone:"Zone 4", zonePoints: 2000, scannedPoints: 100, zoneSafes: 100, scannedSafes: 100 },
       //    {id: 4,   zone:"Zone 5", zonePoints: 2000, scannedPoints: 100, zoneSafes: 100, scannedSafes: 100 },
       //    {id: 4,   zone:"Zone 6", zonePoints: 2000, scannedPoints: 100, zoneSafes: 100, scannedSafes: 100 },
       // ])
   }, []);


   const getTotalSubmissions = () => {
       var db = firebaseConfig.firestore();

        let employeeCountPromise = db.collection('submission_count')
        .doc('count')
        .get()


        Promise.all([employeeCountPromise]).then((values) => {

            setTotalSubmissions( values[0].data().count )

            setCountLoaded(true)
        });


   }


   const fetchChartData  = () => {
        var db = firebaseConfig.firestore();

  




        var employeeSubs = db.collection('submission')
                            .get()


         var companySubs = db.collection('company')
                            .get()



        Promise.all([employeeSubs, companySubs]).then((values) => {


           let dataRange = [],
            dataRangeObject = {};

            values[0].forEach((item) => {
              var jsonData = item.data()
              

              var date = moment(jsonData.created_at * 1000).format('DD/MM');


              if ( dataRangeObject[date]  ) {
                  if (dataRangeObject[date]['employeeCount']) {
                    dataRangeObject[date]['employeeCount'] = dataRangeObject[date]["employeeCount"] + 1
                  } else {
                      dataRangeObject[date]['employeeCount'] = 1
                  }
              } else {
                  dataRangeObject[date] = {}
                  dataRangeObject[date]['employeeCount'] = 1
              }

            })


  


             for (var key in dataRangeObject) {
                dataRange.push({
                  date: key, 
                  employeeCount: dataRangeObject[key]["employeeCount"],
                })
            }


             setChartData(dataRange)
             setChartLoaded(true)


        });




        // .then((snap) =>  {
        //     let dataRange = [],
        //     dataRangeObject = {};
     


        //     snap.forEach((item) => {

        //       var jsonData = item.data()
              
        //       // date 

        //       var date = moment(jsonData.scannedAt * 1000).format('DD/MM');

        //       if ( dataRangeObject[date] ) {
        //           dataRangeObject[date] = dataRangeObject[date] + jsonData.points;
        //       } else {
        //           dataRangeObject[date] =  jsonData.points;
        //       }



        //       // for coin count
        //       if (zoneCoinCount[jsonData.zone]) {
        //         zoneCoinCount[jsonData.zone] = zoneCoinCount[jsonData.zone] + jsonData.points;
        //       } else {
        //         zoneCoinCount[jsonData.zone] =  jsonData.points;
        //       }




        //       // for safe
        //       if (jsonData.safe) {
        //          // zoneSafeCount[jsonData.zone] = "Yes" 
        //          zoneSafeCount[jsonData.zone] =  !zoneSafeCount[jsonData.zone] ? 1 : zoneSafeCount[jsonData.zone] + 1
        //          levelSafeCounts[jsonData.level] = !levelSafeCounts[jsonData.level] ? 1 : levelSafeCounts[jsonData.level] + 1

        //       }

        //       // level points 
        //       if (levelPointCounts[jsonData.level]) {
        //         levelPointCounts[jsonData.level] = levelPointCounts[jsonData.level] + jsonData.points;
        //       } else {
        //         levelPointCounts[jsonData.level] =  jsonData.points;
        //       }          

        //     //   dataRange.push({time: 1 , amount: item.data().points})
        //     })


        //     for (var key in dataRangeObject) {
        //         dataRange.push({
        //           time: key, 
        //           amount: dataRangeObject[key],
        //         })
        //     }


        //     setChartData(dataRange)

        //     // setting loading 
        //     setTableLoaded(true)


        //      getLatestCompanyArray() 

        // })
   }

   const getLatestEmployeeData = () => {

        var db = firebaseConfig.firestore();

        db.collection('submission')
        .orderBy('created_at', "desc")
        .limit(5)
        .get()
        .then((snap) =>  {

            let zoneArray = []

            snap.forEach((item) => {
              let itemData = item.data()

              zoneArray.push({
                name: itemData.name,  
                email: itemData.email, 
                phone_number: itemData.phone_number, 
                gender: itemData.gender, 
                created_at: moment(itemData.created_at * 1000).format('DD/MM/YY'),
              })
            })


            setZoneData(zoneArray)



             // setting loading 
            setTableLoaded(true)


        })
   }


   const getLatestCompanyArray = () => {
      var db = firebaseConfig.firestore();


      
      db.collection('company')
      .orderBy('created_at', "desc")
      .limit(5)
      .get()
      .then((snap) => {
          let levelArray = []


          snap.forEach((item) => {

          let itemData = item.data()


            levelArray.push({
               name: itemData.name,  
                email: itemData.email, 
                company: itemData.company, 
                phone: itemData.phoneNumber, 
                created_at: moment(itemData.created_at * 1000).format('DD/MM/YY'),  
            })  
          })  


         setLevelData(levelArray);

         setLevelTableLoaded(true)

      })

   
   }


  const setHeader = () => {
    return <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                  Reports
           </Typography>
  }



 const renderSubmissionChart = () => {

    if (!chartLoaded) {
      return <CircularProgress className={classes.progressLoader} /> 
    }

    return <SubmissionsChart chartData={chartData} />
 }

 const totalSub = () => {

    if (!countLoaded) {
      return <CircularProgress className={classes.progressLoader} /> 
    }

    return <TotalSubmissions totalSubmissions={totalSubmissions} /> 
 }

 const zoneDataRender = () => {

    if (!tableLoaded) {
      return <CircularProgress className={classes.progressLoader} /> 
    }


    return <ZoneData zoneData={zoneData} />
 }


 const refreshDataTable = () => {


        setChartLoaded(false)
        setTableLoaded(false)
        setLevelTableLoaded(false)
        setCountLoaded(false)

        zoneCoinCount = {};
       zoneSafeCount = {};
       levelPointCounts = {};
       levelSafeCounts = {};


       if (context.actions) {
          context.actions.setPageTopConent(setHeader())
       }


       getTotalSubmissions()
       fetchChartData()


        context.actions.showMessage({
          message: "Dashboard Refreshed",
          type: "success",
        }) 
 }



  return (
        <Container maxWidth="lg" className={classes.container}>
         <IconButton style={{ float: "right", marginTop: "0px" , marginRight: "-5px", color: "#747474"
              }} onClick={refreshDataTable}><RefreshIcon/></IconButton>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={9}>
              <Paper className={fixedHeightPaper}>
                { renderSubmissionChart() }
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                  { totalSub() }
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                { zoneDataRender() }
              </Paper>
            </Grid>

           
          </Grid>
        </Container>
  );
}