import React, {useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PrintIcon from '@material-ui/icons/Print'
const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

});

const useStyles = makeStyles({
  table: {
    minWidth: 400,
  }, 
  cellStrong: {
    fontWeight: 'bold',
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);





export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);


     props.handleClose()
  };

  useEffect(() => {

      if (!!props.show) {
        setOpen(true)
      } 

   }, [props.show]);


  const camelCaseToWords = (value) => {

    return value
    .replace(/([A-Z])/g, ' $1')
    // uppercase the first character
    .replace(/^./, function(str){ return str.toUpperCase(); })
  }
  const getDataTable = (data) => {

  

    let dataTable = [];
    for (var key in data) {
        dataTable.push(<TableRow key={key}>
                          <TableCell component="th" scope="row">
                            {camelCaseToWords(key)}
                          </TableCell>
                          <TableCell align="right">{data[key]}</TableCell>
                        </TableRow>)
    }


      // return rows.map(row => (
      //             <TableRow key={row.name}>
      //               <TableCell className={classes.cellStrong} component="th" scope="row">
      //                 {row.name}
      //               </TableCell>
      //               <TableCell align="right">{row.calories}</TableCell>
      //             </TableRow>
      //           ))


    return dataTable
  }

 const printItem = () => {
    console.log("print")
  }
  return (
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Details  
          {/*<IconButton onClick={printItem} href="" target="_blank"><PrintIcon/></IconButton>*/}
        </DialogTitle>
        <DialogContent dividers>
            <Table className={classes.table} aria-label="simple table">
 {/*             <TableHead>
                <TableRow>
                  <TableCell>Dessert (100g serving)</TableCell>
                  <TableCell align="right">Calories</TableCell>
                </TableRow>
              </TableHead>*/}
              <TableBody>
                { getDataTable(props.data) }
              </TableBody>
            </Table>
     
        </DialogContent>
      </Dialog>
  );
}