import React, {useEffect, useContext, useState} from 'react';
import { withRouter, Redirect } from "react-router";



// Material UI
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import ViewMoreIcon from '@material-ui/icons/RemoveRedEye'

import ResolvedIcon from '@material-ui/icons/CheckCircle'
import FraudIcon  from '@material-ui/icons/Error'

import UnResolvedIcon from '@material-ui/icons/HourglassEmptyRounded'
import IgnoreIcon from '@material-ui/icons/Close'

import ActionIcon from '@material-ui/icons/Build'
import StarIcon from '@material-ui/icons/Star'
import RefreshIcon from '@material-ui/icons/Refresh'

import DownloadIcon from '@material-ui/icons/CloudDownload';

// providers
import  MainProvider  from "../common/MainProvider";
import AuthContext from '../common/AuthProvider';
import MainContext from '../common/MainProvider';

// custom
// import DataTable from '../components/table.jsx';
import DataTable from '../components/dataTable';
import CustomizedDialogs from '../components/moreDialog';
import ActionDialoge from '../components/actionDialoge';

import namor from 'namor';
import uuid from 'uuid';
import moment from 'moment';


//firebase connection 
import firebaseConfig from '../config/firebaseConfig';


const useStyles = makeStyles((theme) => {

  return ({
    title: {
      flexGrow: 1 ,
    },
    checkMark: {
      color: theme.palette.secondary.main
    },

    starIcon: {
      color: "#FFD700"
    },
    isWinner: {
      color: theme.palette.secondary.main
    },

})});


function PaperComponent(props) {
  return (
      <Paper {...props} />
  );
}


var tableName = 'submission',
defaultSortColomn = 'created_at'

var oldQuery = null, 
startDoc = [], 
endDoc = null,
page = 0,
rowsPerPage = 10,
orderBy = defaultSortColomn,
orderDir = 'desc';


var db = firebaseConfig.firestore();


export default  function EmployeeSubmissions() {

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [data, setData] = useState([]);
  // const [oldQuery, setOldQuery] = useState(null);
  const [recordCount, setRecordCount] = useState(null);
  const [pointsCols, setPointsCols] = useState([]);
  const [searchText, setSearchText] = useState();
  const [currentRecord, setCurrentRecord] = useState({});
  const [showDialoge, setShowDialoge] = useState(false);

  const [showActionDialoge, setShowActionDialoge] = useState(false);



  const context  = useContext(MainContext);
   
  const classes = useStyles();


   useEffect(() => {

       if (context.actions) {
          context.actions.setPageTopConent(setHeader())
       }


        refreshSubmissions() 

   }, []);


  const setHeader = () => {
    return ( 
      <React.Fragment>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            View Sumissions
        </Typography>
        <Divider orientation="vertical" />
      </React.Fragment>
    )

  }




   const refreshSubmissions = () => {
        oldQuery = null 
        startDoc = [] 
        endDoc = null
        page = 0

        fetchSubmissions()
   }


  const fetchSubmissions = (state = null) => {

      if (oldQuery) {

        if (state.page > page ) {
           oldQuery = oldQuery.startAfter(endDoc)
         } else {

            oldQuery = oldQuery.endBefore(startDoc)
         }

        page =  state.page
      } else {

        // set total number of count 
        db
          .collection(`${tableName}_count`)
          .doc("count")
          .get()
          .then((item ) => {
            setRecordCount(item.data().count)
          });

       // get submissions    
         oldQuery = db
          .collection(tableName)
          .orderBy(orderBy, orderDir)
          .limit(rowsPerPage);
      }

      executeQuery(oldQuery)
      
  }

  const handleChangePage = (pageNumber) => {
        
        if (pageNumber > page ) {
           oldQuery = oldQuery.startAfter(endDoc)
         } else {
            oldQuery = oldQuery.startAt(startDoc[pageNumber])
         }

        page =  pageNumber

        executeQuery(oldQuery)
  } 

  const viewMoreAction =  async (item) => {

    let object = {
      FullName: item.name,
      Email: item.email,
      // phone_number: item.phone_number,
      City: item.city,
      Gender: item.gender,
      Image: <IconButton href={item.CV} target="_blank"><DownloadIcon/></IconButton>,
      SubmitedOn: moment(item.created_at * 1000).format('hh:mm:ss DD/MM/YY'),
    }

    setCurrentRecord(object)

    setShowDialoge(true)
  } 


  const takeAction =  async (item) => {

    let object = {
      key: item.key,
      FullName: item.name,
      Email: item.email,
      // phone_number: item.phone_number,
      City: item.city,
      Image: <IconButton href={item.CV} target="_blank"><DownloadIcon/></IconButton>,
      SubmitedOn: moment(item.created_at * 1000).format('DD/MM/YY'),

    }
    setCurrentRecord(object)

    setShowActionDialoge(true)
  } 

  const handleRowChange = (count) => {
    rowsPerPage = count

    refreshSubmissions()  
   }

   const ignoreAction = (itemJson) => {

    if (window.confirm("Are you sure you want to ignore this?")) {
      db
      .collection(tableName)
      .doc(itemJson.key)
      .update({
        is_ignored: true,
        // is_resolved: true
      })
      refreshSubmissions()  
    }
   
   }

  const executeQuery = (oldQuery) => {

     oldQuery.get()
          .then((snap) => {


              endDoc = snap.docs[snap.docs.length - 1]
              // startDoc = snap.docs[0]


              // let startDocObj = {
                  
              // }

              // startDocObj[page] = snap.docs[0]
              startDoc.push(snap.docs[0])

              let newData = [];
              let havePointsHistory = null


              snap.forEach((item) => {

                const itemJson = item.data()
                itemJson.key = item.id

                let statusLabe

                if (itemJson.is_ignored) {
                  statusLabe = <Chip style={{backgroundColor:'#F44336'}} color="primary" size="small" label="Ignored" icon={<IgnoreIcon />} />
                }
                else if  (!itemJson.is_resolved) {
                  statusLabe = <Chip color="secondry" size="small" label="Pending Review" icon={<UnResolvedIcon />} />
                }
                
                else if (itemJson.is_fraud) {
                   statusLabe = <Chip style={{backgroundColor:'#F44336'}} color="primary" size="small" label="Is Fraud" icon={<FraudIcon />} />
                } else {
                  statusLabe = <Chip color="primary" size="small" label="Not Fraud" icon={<ResolvedIcon />} />
                }

                let actionItems 

                if (!itemJson.is_resolved &&  !itemJson.is_ignored) {
                  actionItems = [ <a href="#" onClick={ (item) => takeAction(itemJson) } className={classes.checkMark}><ActionIcon/></a>,
                  <a href="#" onClick={ (item) => ignoreAction(itemJson) } className={classes.checkMark}><IgnoreIcon/></a>]
                }
                let newDataArray = [
                    itemJson.name,
                    itemJson.email,
                    itemJson.city,
                    <IconButton href={itemJson.CV} target="_blank"><DownloadIcon/></IconButton>,
                    statusLabe,
                    moment(itemJson.created_at * 1000).format('DD/MM/YY'),
                    <React.Fragment>
                      <a href="#" onClick={ (item) => viewMoreAction(itemJson) } className={classes.checkMark}><ViewMoreIcon/></a>
                      {actionItems}
                    </React.Fragment>
                  ]

                newData.push(newDataArray)
              })


              setData(newData) 

             
          })
  }



  const handleSearchChange = (text) => {

      setSearchText(text)

      if (text) {

         oldQuery = db
                    .collection(tableName)
                    .where("email", '==', text)
                    // .startAt(text)
                    // .endAt(text+ "\uf8ff")
                    // .orderBy('name')
                    // .startAt(text)
                    // 
                        .limit(rowsPerPage)

          
      } else {
        oldQuery = db
                      .collection(tableName)
                      .orderBy(orderBy, orderDir)
                          .limit(rowsPerPage)
      }

      
 
  }

  const handleStatusFilter = (type, value) => {
    if (type == 'Unresolved') {
      oldQuery = db
        .collection(tableName)
        .where("is_resolved", '==', false)
        .limit(rowsPerPage)
    }  else if (type == 'Resolved') {
      oldQuery = db
        .collection(tableName)
        .where("is_resolved", '==', true)
            .limit(rowsPerPage)
    } else {
      oldQuery = db
                    .collection(tableName)
                    .orderBy(orderBy, orderDir)
                        .limit(rowsPerPage)
    }


    executeQuery( oldQuery )
  }


  const handleSortChange = (col,direction) => {
      if (direction == 'descending') {

          orderBy = defaultSortColomn
          orderDir = "desc"
      } else {
          
          orderBy = defaultSortColomn
          orderDir = "asc"
      }

      oldQuery = db
                    .collection(tableName)
                    .orderBy(orderBy, orderDir)
                        .limit(rowsPerPage)

      executeQuery(oldQuery)
  }





  const refreshDataTable = () => {
      context.actions.showProgress(true)    

       refreshSubmissions()
             context.actions.showMessage({
            message: "Submissions Refreshed",
            type: "success",
          }) 

          context.actions.showProgress(false) 
  }

  const handleDatatable = () => {
    return <React.Fragment>
              <IconButton style={{ float: "right", marginTop: "10px" , marginRight: "20px", color: "#747474"
              }} onClick={refreshDataTable}><RefreshIcon/></IconButton>
              <DataTable 
              totalCount={recordCount}
              data={ data }
              pointsCols={ pointsCols }
              handleSearchChange={ handleSearchChange }
              handleSortChange={ handleSortChange }
              handleChangePage={ handleChangePage }
              handleRowChange={ handleRowChange }
              searchText={ searchText }
              tableTitle={`Submissions (${recordCount})`}
              tableColomns={
                 [
                    {
                      label: 'Name',
                      name: 'Name',
                      options: {
                        filter: false,
                        sort: false 
                      },
                    },
                    {
                      name: 'Email',
                      options: {
                        filter: false,
                        sort: false 
                      },
                    },
                    {
                      name: 'City',
                      options: {
                        filter: false,
                        sort: false 
                      },
                    },
                    {
                      name: 'Image',
                      options: {
                        filter: false,
                        sort: false 
                      },
                    },
                    
                    {
                      name: 'Resolution',
                      options: {
                        filter: true,
                        filterType: 'custom',

                  filterOptions: {
                
                    display: (filterList, onChange, index, column) => {
                      const optionValues = ['All', 'Unresolved', 'Resolved'];
                      return (
                        <FormControl>
                          <InputLabel htmlFor='select-multiple-chip'>
                            Resolution
                          </InputLabel>
                          <Select
                            value={filterList[index]}
                            onChange={event => {
                              filterList[index] = event.target.value;
                              onChange(filterList[index], index, column);
                              handleStatusFilter( event.target.value)
                            }}
                          >
                            {optionValues.map(item => (
                              <MenuItem key={item} value={item}>
                          
                                <ListItemText primary={item} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      );
                    },
                  },
                        sort: false 
                      },
                    },

                    {
                    name: 'Submited On',
                      options: {
                        filter: false,
                        sort: true,
                        sortDirection: orderDir,
                      },
                    },{
                      label: 'Action',
                      name: 'View',
                      options: {
                        filter: false,
                        sort: false,
                        print: false,                    
                        download: false,                    
                      },
                    }
                  ]
               }
               />
            </React.Fragment>

  } 
  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
            <Container maxWidth="lg" style={{marginTop: 20}} className={classes.container}>
              {handleDatatable()}
            </Container>

            <CustomizedDialogs show={showDialoge} data={currentRecord} handleClose={() => {
                setShowDialoge(false)
              }}/>

            <ActionDialoge show={showActionDialoge} data={currentRecord} handleClose={() => {
                            setShowActionDialoge(false)
                          }} handleAction={ (action) => {
                            
                        db
                          .collection(tableName)
                          .doc(currentRecord.key)
                          .update(action)

                          refreshSubmissions()

                        context.actions.showMessage({
                          message: "Submission is resolved",
                          type: "success",
                        }) 
            }}/>
            
      </Grid>
    </Grid>
  </Container>
  );
}
