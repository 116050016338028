import React, {Component} from 'react';


const MainContext = React.createContext();

export class MainProvider extends Component {
   
   state = {
   		sidebarOpen: true,
         pageTitle: 'Default Title',
   		notficationOpen: false,
         showMessage: false,
         showProgress: false,
   }

   handleDrawerOpen = () => {
   		this.setState({
   			sidebarOpen: true,
   		})
   }

   handleDrawerClose = () => {
   		this.setState({
   			sidebarOpen: false,
   		})
   }

   setPageTitle = (title) => {
   		this.setState({
   			pageTitle: title,
   		})
   }

   setPageTopConent = (content) => {
         this.setState({
            topPageContent: content
         })
   }

   toggleNoticationOpen = () => {
      this.setState({
         notficationOpen: !this.state.notficationOpen
      })
   }


   showMessage = (data) => {

       this.setState({
         showMessage: data
      })
   } 

   showProgress = (value) => {

       this.setState({
         showProgress: value
      })
   }


   render() {
   	return (
   		<MainContext.Provider value={{
   			state: this.state,
   			actions: {
   				handleDrawerOpen: this.handleDrawerOpen,
   				handleDrawerClose: this.handleDrawerClose,
               setPageTitle: this.setPageTitle,
               setPageTopConent: this.setPageTopConent,
               toggleNoticationOpen: this.toggleNoticationOpen,
               showMessage: this.showMessage,
   				showProgress: this.showProgress,
   			} 
   		}}>
   			{ this.props.children }
   		</MainContext.Provider>
   		);
   }
}

export const MainConsumer = MainContext.Consumer
export default  MainContext