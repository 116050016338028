import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';


import {MainConsumer} from '../common/MainProvider';
import { mainListItems, secondaryListItems } from './menulist';
import Logo from './../logo2.png'

import {drawerWidth} from '../config/globals';


const useStyles = makeStyles(theme => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
}));


export default function Sidebar() {
  const classes = useStyles();

  return (
     <MainConsumer>
        {(context) => (
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(classes.drawerPaper, !context.state.sidebarOpen && classes.drawerPaperClose),
            }}
            open={context.state.sidebarOpen}
          >
            <div className={classes.toolbarIcon}>
              <img src={Logo} style={{width: "118px", marginRight: "20px"} } />
              <IconButton onClick={context.actions.handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>{mainListItems}</List>
            {/*<Divider />*/}
            {/*<List>{secondaryListItems}</List>*/}
        </Drawer>
        )}
       
      </MainConsumer> 
  
  )
}
