import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ListIcon from '@material-ui/icons/List';
import PersonIcon from '@material-ui/icons/Person';
import CompanyIcon from '@material-ui/icons/Contacts';
import NotificationIcon from '@material-ui/icons/NotificationImportant';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import SettingsIcon from '@material-ui/icons/Settings';
import {Link} from 'react-router-dom';
// import Link from '@material-ui/core/Link';

const styles = {
  link: { textDecoration: 'none', color: "#000000de" }
}
export const mainListItems = (
  <React.Fragment>

     <Link to="/" style={styles.link}>
      <ListItem button>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItem>
    </Link>


    <Link to="/submissions" style={styles.link}>
      <ListItem button>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="Submissions" />
      </ListItem>
    </Link> 

  </React.Fragment>
);

export const secondaryListItems = (
  <div>
   <Link to="/settings" style={styles.link}>
    <ListItem button>
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Settings" />
    </ListItem>
    </Link>
  </div>
);