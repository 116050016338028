import React, {useEffect, useContext, useState} from 'react';
import { withRouter, Redirect } from "react-router";



// Material UI
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import ViewMoreIcon from '@material-ui/icons/RemoveRedEye'
import StarIcon from '@material-ui/icons/Star'
import RefreshIcon from '@material-ui/icons/Refresh'

import DownloadIcon from '@material-ui/icons/CloudDownload';

// providers
import  MainProvider  from "../common/MainProvider";
import AuthContext from '../common/AuthProvider';
import MainContext from '../common/MainProvider';

// custom
// import DataTable from '../components/table.jsx';
import DataTable from '../components/dataTable';
import CustomizedDialogs from '../components/moreDialog';

import namor from 'namor';
import uuid from 'uuid';
import moment from 'moment';



//firebase connection 
import firebaseConfig from '../config/firebaseConfig';


const useStyles = makeStyles((theme) => {

  return ({
  title: {
    flexGrow: 1 ,
  },
  checkMark: {
    color: theme.palette.secondary.main
  },

  starIcon: {
    color: "#FFD700"
  },
  isWinner: {
    color: theme.palette.secondary.main
  },

})});


function PaperComponent(props) {
  return (
      <Paper {...props} />
  );
}


var tableName = 'company',
defaultSortColomn = 'created_at'

var oldQuery = null, 
startDoc = [], 
endDoc = null,
page = 0,
rowsPerPage = 10,
orderBy = defaultSortColomn,
orderDir = 'desc';


var db = firebaseConfig.firestore();


export default  function CompanySubmissions() {

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [data, setData] = useState([]);
  // const [oldQuery, setOldQuery] = useState(null);
  const [recordCount, setRecordCount] = useState(null);
  const [pointsCols, setPointsCols] = useState([]);
  const [searchText, setSearchText] = useState();
  const [currentRecord, setCurrentRecord] = useState({});
  const [showDialoge, setShowDialoge] = useState(false);



  const context  = useContext(MainContext);
   
  const classes = useStyles();


   useEffect(() => {

       if (context.actions) {
          context.actions.setPageTopConent(setHeader())
       }


        refreshSubmissions() 

   }, []);


  const setHeader = () => {
    return ( 
      <React.Fragment>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            View Companys
        </Typography>
        <Divider orientation="vertical" />
      </React.Fragment>
    )

  }




   const refreshSubmissions = () => {
        oldQuery = null 
        startDoc = [] 
        endDoc = null
        page = 0

        fetchSubmissions()
   }


  const fetchSubmissions = (state = null) => {

      if (oldQuery) {

        if (state.page > page ) {
           oldQuery = oldQuery.startAfter(endDoc)
         } else {

            oldQuery = oldQuery.endBefore(startDoc)
         }

        page =  state.page
      } else {

        // set total number of count 
        db
          .collection(`${tableName}_count`)
          .doc("count")
          .get()
          .then((item ) => {
            setRecordCount(item.data().count)
          });

       // get submissions    
         oldQuery = db
          .collection(tableName)
          .orderBy(orderBy, orderDir)
          .limit(rowsPerPage);
      }

      executeQuery(oldQuery)
      
  }

  const handleChangePage = (pageNumber) => {
        
        if (pageNumber > page ) {
           oldQuery = oldQuery.startAfter(endDoc)
         } else {
            oldQuery = oldQuery.startAt(startDoc[pageNumber])
         }

        page =  pageNumber

        executeQuery(oldQuery)
  } 

  const viewMoreAction =  async (item) => {

    let object = {
      FullName: item.name,
      Email: item.email,
      CompanyName: item.company,
      PhoneNumber: item.phoneNumber,
      Message: item.message,
      SubmitedOn: moment(item.created_at * 1000).format('DD/MM/YY'),

    }

    setCurrentRecord(object)

    setShowDialoge(true)
  } 

  const handleRowChange = (count) => {
    rowsPerPage = count

    refreshSubmissions()  
   }

  const executeQuery = (oldQuery) => {

     oldQuery.get()
          .then((snap) => {


              endDoc = snap.docs[snap.docs.length - 1]
              // startDoc = snap.docs[0]


              // let startDocObj = {
                  
              // }

              // startDocObj[page] = snap.docs[0]
              startDoc.push(snap.docs[0])

              let newData = [];
              let havePointsHistory = null


              snap.forEach((item) => {

                const itemJson = item.data()


                let newDataArray = [
                    itemJson.name,
                    itemJson.email,
                    itemJson.company,
                    itemJson.phoneNumber,
                    moment(itemJson.created_at * 1000).format('DD/MM/YY'),
                    <a href="#" onClick={ (item) => viewMoreAction(itemJson) } className={classes.checkMark}><ViewMoreIcon/></a>
                  ]

                newData.push(newDataArray)
              })


              setData(newData) 

             
          })
  }



  const handleSearchChange = (text) => {

      setSearchText(text)

      if (text) {

         oldQuery = db
                    .collection(tableName)
                    .where("email", '==', text)
                    // .startAt(text)
                    // .endAt(text+ "\uf8ff")
                    // .orderBy('name')
                    // .startAt(text)
                    // 
                        .limit(rowsPerPage)

          
      } else {
        oldQuery = db
                      .collection("company")
                      .orderBy(orderBy, orderDir)
                          .limit(rowsPerPage)
      }

      executeQuery( oldQuery )
 
  }


  const handleSortChange = (col,direction) => {
      if (direction == 'descending') {

          orderBy = defaultSortColomn
          orderDir = "desc"
      } else {
          
          orderBy = defaultSortColomn
          orderDir = "asc"
      }

      oldQuery = db
                    .collection("company")
                    .orderBy(orderBy, orderDir)
                        .limit(rowsPerPage)

      executeQuery(oldQuery)
  }





  const refreshDataTable = () => {
      context.actions.showProgress(true)    

       refreshSubmissions()
             context.actions.showMessage({
            message: "Submissions Refreshed",
            type: "success",
          }) 

          context.actions.showProgress(false) 
  }

  const handleDatatable = () => {
    return <React.Fragment>
              <IconButton style={{ float: "right", marginTop: "10px" , marginRight: "20px", color: "#747474"
              }} onClick={refreshDataTable}><RefreshIcon/></IconButton>
              <DataTable 
              totalCount={recordCount}
              data={ data }
              pointsCols={ pointsCols }
              handleSearchChange={ handleSearchChange }
              handleSortChange={ handleSortChange }
              handleChangePage={ handleChangePage }
              handleRowChange={ handleRowChange }
              searchText={ searchText }
              tableTitle={`Company (${recordCount})`}
              tableColomns={
                 [
                    {
                      label: 'Name',
                      name: 'Name',
                      options: {
                        filter: false,
                        sort: false 
                      },
                    },
                    {
                      name: 'Email',
                      options: {
                        filter: false,
                        sort: false 
                      },
                    },
                    {
                      name: 'Company Name',
                      options: {
                        filter: false,
                        sort: false 
                      },
                    },
                    {
                      name: 'Phone',
                      options: {
                        filter: false,
                        sort: false 
                      },
                    }, 

                    {
                    name: 'Submited On',
                      options: {
                        filter: false,
                        sort: true,
                        sortDirection: orderDir,
                      },
                    },{
                      label: 'Action',
                      name: 'View',
                      options: {
                        filter: false,
                        sort: false,
                        print: false,                    
                        download: false,                    
                      },
                    }
                  ]
               }
               />
            </React.Fragment>

  } 
  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
            <Container maxWidth="lg" style={{marginTop: 20}} className={classes.container}>
              {handleDatatable()}
            </Container>

            <CustomizedDialogs show={showDialoge} data={currentRecord} handleClose={() => {
                setShowDialoge(false)
              }}/>
            
      </Grid>
    </Grid>
  </Container>
  );
}
