import React, { useState, useEffect, useContext }from 'react';
import clsx from 'clsx';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import LinearProgress from '@material-ui/core/LinearProgress';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/ExitToApp';
import Button from '@material-ui/core/Button';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Grid from '@material-ui/core/Grid';
import MainContext from '../common/MainProvider';
import { AuthContext } from '../common/AuthProvider';


import {MainConsumer} from '../common/MainProvider';
import firebaseConfig from '../config/firebaseConfig';
import {drawerWidth} from '../config/globals';

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  snackbar: {
    margin: theme.spacing(1),
  }
}));


export default function Topbar() {

  const [notoficationData, setNotoficationData] = useState({
      count: 0,
      data: []
  });
  const [editableTitle, toggleEdit] = useState(false);
  const [showButton] = useState(false);

  const context  = useContext(MainContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);


  const { currentBusiness, currentUser } = useContext(AuthContext);

  useEffect(() => {
      
      if (currentBusiness) {
        var db = firebaseConfig.firestore();
        db
          .collection("notification_count")
          .doc(currentUser.uid)
          .onSnapshot((querySnapshot) => {

              if (querySnapshot.exists) {
                const data = querySnapshot.data()
                setNotoficationData({
                    count: data.count,
                    data: null
                })
              }
              
          })
      }
      

  }, []);

  const classes = useStyles();



   const topEditArea = ( context, classes ) => {


      // if (context.state.pageTitle) {
      //     return <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
      //               {context.state.pageTitle}
      //           </Typography>
      // }
      // if (context.state.topPageContent) {
      //   return <React.Fragment>
      //               { context.state.topPageContent }
      //           </React.Fragment>
      // }

      // return  context.state.topPageContent 

      if (!context.state.topPageContent) {
            return <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                     Default Titles
                   </Typography>
      } else {
          return context.state.topPageContent 
      }
          
   }
   const handleMenu = event => {
      if (anchorEl) {
        setAnchorEl( null )
      } else {
        setAnchorEl(event.currentTarget);
      }
      
   };


   const handleLogout = () => {
      setAnchorEl(null);

       firebaseConfig
        .auth()
        .signOut()
    };


    const handleNotificationToggle = () => {
       context.actions.toggleNoticationOpen() 
    } 

   const showProgress = (context) => {
      if (context.state.showProgress) {
        return <LinearProgress />
      }
   }
   return (

    <MainConsumer>
        {(context) => (
          <React.Fragment>
          <AppBar position="absolute" className={clsx(classes.appBar, context.state.sidebarOpen && classes.appBarShift)}>
              <Toolbar className={classes.toolbar}>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={context.actions.handleDrawerOpen}
                  className={clsx(classes.menuButton, context.state.sidebarOpen && classes.menuButtonHidden)}
                >
                  <MenuIcon />
                </IconButton>
                {topEditArea( context,classes )}
         {/*       <IconButton color="inherit" onClick={handleNotificationToggle}>
                  <Badge badgeContent={notoficationData.count} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>*/}


                {/*<a onClick={handleLogout} href="#" style={{color: "#fff", fontWeight:"bold", textDecoration: "none"}}>Logout</a>*/}
                <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleLogout}
                color="inherit"
              >
                <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleMenu}
                  >
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
              </Toolbar>
              { showProgress(context)  }
            </AppBar>
            
            </React.Fragment>

      )}
       
      </MainConsumer> 
    );
}