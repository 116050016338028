// react related
import React, { useContext, useEffect } from 'react';
import { withRouter, Redirect } from "react-router";

// material ui
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';


import Logo from '../rb-logo.svg';

// providers
import  MainProvider  from "../common/MainProvider";
import { AuthContext } from '../common/AuthProvider';

//firebase connection 
import firebaseConfig from '../config/firebaseConfig';

// styles
const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));




export default function Login( history ) {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);


  const context = useContext(MainProvider);


   const [values, setValues] = React.useState({
    emailError: false,
    passwordError: false,
  });


    const  handleSignIn = async (e) =>  {

	    e.preventDefault();
	    const { email, password } = e.target.elements;

	    let newState = {}
	    let hasError = false
	    if (email.value == "") {
	        newState.emailError = true
	        hasError = true
	    }

	    if (password.value == "") {
	        newState.passwordError = true
	        hasError = true
	    }


	    setValues( newState )


	    if (!hasError) {
	      setLoading(true);
	      try {
	        await firebaseConfig
	          .auth()
	          .signInWithEmailAndPassword(email.value, password.value);

	        setLoading(false);
	        // history.push("/submissions");

	      } catch (error) {

	         context.actions.showMessage({
	            type: 'error',
	            message: error.message,
	         });
	      }
	    }
	  }
  
  const { currentUser } = useContext(AuthContext);
  	useEffect(() => {
  }, []);

  if (currentUser) {
    return <Redirect to="/" />;
  }



  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form onSubmit={handleSignIn} className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={values.emailError}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            error={values.passwordError}
          />
     
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          
        </form>
      </div>
      
    </Container>
  );
}