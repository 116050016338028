/* eslint-disable no-script-url */

import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';



const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function ZoneData(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Title>Recent Compnay (Showing 5)</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            {/*<TableCell align="right">Total Coins</TableCell>*/}
            <TableCell>Email</TableCell>
            <TableCell>Compnay Name</TableCell>
            <TableCell>Phone Number</TableCell>
            {/*<TableCell align="right">Total Safes</TableCell>*/}
            <TableCell>Submited On</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.levelData.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.company}</TableCell>
              <TableCell >{row.phone}</TableCell>
              <TableCell>{row.created_at}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        <Link color="primary" href="company-submissions">
          See All Companys
        </Link>
      </div>
    </React.Fragment>
  );
}
