import React from 'react';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import Title from './Title';


export default function Chart(props) {
  return (
    <React.Fragment>
      <Title>Submissions</Title>
      <ResponsiveContainer>
        <LineChart
          data={props.chartData}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }} >
          <XAxis dataKey="date" />
          <YAxis>
            <Label angle={270} position="left" style={{ textAnchor: 'middle' }}>
              Count
            </Label>
          </YAxis>
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="Submission" stroke="#556CD6" dot={true} />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
